// Generated by Framer (25411b0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/CzT09H_jw";

const cycleOrder = ["ttZ3MGlD3", "ccbuQiRAP"];

const serializationHash = "framer-eZDow"

const variantClassNames = {ccbuQiRAP: "framer-v-1qchis3", ttZ3MGlD3: "framer-v-9bkjgp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "ttZ3MGlD3", "Variant 2": "ccbuQiRAP"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, DUI88HiaD: link ?? props.DUI88HiaD, Tu00UojNt: title ?? props.Tu00UojNt ?? "Terms & conditions of use", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ttZ3MGlD3"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Tu00UojNt, DUI88HiaD, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ttZ3MGlD3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter13vs97a = activeVariantCallback(async (...args) => {
setVariant("ccbuQiRAP")
})

const onMouseLeave1c3tsxn = activeVariantCallback(async (...args) => {
setVariant("ttZ3MGlD3")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Link href={DUI88HiaD}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-9bkjgp", className, classNames)} framer-1qosyuy`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ttZ3MGlD3"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter13vs97a} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ccbuQiRAP: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave1c3tsxn}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-mrlq3"} data-styles-preset={"CzT09H_jw"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-d764369c-aa92-4484-810e-c05177bb2613, rgb(114, 114, 114)))"}}>Terms &amp; conditions of use</motion.p></React.Fragment>} className={"framer-1lx2azh"} layoutDependency={layoutDependency} layoutId={"d0LCm_s4C"} style={{"--extracted-r6o4lv": "var(--token-d764369c-aa92-4484-810e-c05177bb2613, rgb(114, 114, 114))"}} text={Tu00UojNt} variants={{ccbuQiRAP: {"--extracted-r6o4lv": "var(--token-c3a55a0f-d2a0-4565-bb3a-bbd6f1087b74, rgb(255, 255, 255))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({ccbuQiRAP: {children: <React.Fragment><motion.p className={"framer-styles-preset-mrlq3"} data-styles-preset={"CzT09H_jw"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-c3a55a0f-d2a0-4565-bb3a-bbd6f1087b74, rgb(255, 255, 255)))"}}>Terms &amp; conditions of use</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-eZDow[data-border=\"true\"]::after, .framer-eZDow [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eZDow.framer-1qosyuy, .framer-eZDow .framer-1qosyuy { display: block; }", ".framer-eZDow.framer-9bkjgp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-eZDow .framer-1lx2azh { -webkit-user-select: none; flex: none; height: auto; pointer-events: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eZDow.framer-9bkjgp { gap: 0px; } .framer-eZDow.framer-9bkjgp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-eZDow.framer-9bkjgp > :first-child { margin-left: 0px; } .framer-eZDow.framer-9bkjgp > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 10
 * @framerIntrinsicWidth 135
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ccbuQiRAP":{"layout":["auto","auto"]}}}
 * @framerVariables {"Tu00UojNt":"title","DUI88HiaD":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerh6UbXEJmt: React.ComponentType<Props> = withCSS(Component, css, "framer-eZDow") as typeof Component;
export default Framerh6UbXEJmt;

Framerh6UbXEJmt.displayName = "Footer small link";

Framerh6UbXEJmt.defaultProps = {height: 10, width: 135};

addPropertyControls(Framerh6UbXEJmt, {variant: {options: ["ttZ3MGlD3", "ccbuQiRAP"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, Tu00UojNt: {defaultValue: "Terms & conditions of use", displayTextArea: false, title: "Title", type: ControlType.String}, DUI88HiaD: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerh6UbXEJmt, [...sharedStyle.fonts])